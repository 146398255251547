import React, { useEffect, useState } from "react";
import { Button, Grid, styled, TextField, Typography } from "@mui/material";
import logo from "../assets/istash.png";
import { colors } from "../utils/configurations";
import facebook from "../assets/facebook.png";
import insta from "../assets/insta.png";
import linkedin from "../assets/linkedin.png";
import http from "../utils/http-common";
import { toast } from "react-toastify";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "50px",
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputBase-input": {
    color: "white",
    textAlign: "center",
  },
  "& .MuiInputLabel-root": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  width: "100%", // Make it full width for better responsiveness
  maxWidth: "250px", // Limit max width
  margin: "10px",
}));

const JoinUs = () => {
  const [formData, setFormData] = useState<any>({});

  const currentUrl = window.location.href;

  const id = currentUrl.split("join-us/")[1];

  console.log(id);
  useEffect(() => {
    if (id) {
      setFormData({ id });
    }
  }, [id]);
  console.log(formData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (formData.email && formData.first_name && formData.last_name) {
      try {
        const response = await http.post("api/istash/join-us", formData);
        console.log(response);
        toast.success("Welcome to istash!");
      } catch (error: any) {
        console.log(error);
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.errorMessage);
        } else {
          alert(error.message);
        }
      }
    } else {
      alert("Please Enter Details");
    }
  };

  return (
    <div>
      <Grid
        container
        sx={{
          background: colors.theme,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "20px 0",
        }}
      >
        {/* Logo */}
        <Grid item xs={12} textAlign="center">
          <img
            style={{ width: "250px", maxWidth: "100%" }}
            src={logo}
            alt="logo"
          />
        </Grid>

        {/* Main Title */}
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Abril Fatface, serif",
              color: "white",
              fontSize: { xs: "1.5rem", sm: "2rem" },
            }}
          >
            Here’s your exclusive
            <br /> invitation to join istash
          </Typography>
        </Grid>

        {/* Description */}
        <Grid item xs={12} textAlign="center" sx={{ p: "20px 0" }}>
          <Typography
            sx={{ fontFamily: "Poppins sans-serif" ,color: "white", fontSize: { xs: "0.9rem", sm: "1rem" } }}
          >
            If earning at least 3% instant cash back on every purchase doesn’t
            <br />
            excite you, do not proceed
            <br />
            <br /> But if you like free cash, email us to download istash
            <br /> on App Store and Play store as soon as it releases
          </Typography>
        </Grid>

              <Grid sx={{background:'white', p:'10px 10px 10px 10px', borderRadius:'50px'}}>
                  <Typography variant="h5" sx={{color:colors.theme,fontWeight:'bold' }}>Coming Soon</Typography>
              </Grid>
        {/* Form Section */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ paddingTop: "20px" }}
        >
          <Grid item sx={{ p: "10px 10px 10px 10px" }}>
            <StyledTextField
              variant="outlined"
              placeholder="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sx={{ p: "10px 10px 10px 10px" }}>
            <StyledTextField
              variant="outlined"
              placeholder="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{display:'flex', justifyContent:'center'}}>
            <StyledTextField
              variant="outlined"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              sx={{ maxWidth: { xs: "90%", sm: "300px" }, margin: "auto" }} // Responsive width
            />
          </Grid>
        </Grid>

        {/* Button */}
        <Grid item xs={12} textAlign="center" sx={{ padding: "20px 0" }}>
          <Button
            variant="outlined"
            sx={{
              color: "white",
              borderRadius: "25px",
              border: "1px solid white",
              padding: "10px 20px",
            }}
            onClick={handleSave}
          >
            Join Us
          </Button>
        </Grid>

        {/* Social Media Icons */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "20px 0" }}
        >
          {[
            {
              src: facebook,
              alt: "facebook",
              link: "https://www.facebook.com/people/Istash/61566014381694/",
            },
            { src: linkedin, alt: "linkedin", link: "#" },
            {
              src: insta,
              alt: "insta",
              link: "https://www.instagram.com/istash.shop/",
            },
          ].map((icon) => (
            <Grid item key={icon.alt} sx={{ padding: "10px" }}>
              <img
                style={{ width: "40px", cursor: "pointer" }}
                src={icon.src}
                alt={icon.alt}
                onClick={() => window.open(icon.link, "_blank")}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default JoinUs;
